import get from '@invitato/helpers/dist/getLocale';

export default {
  title: get('WAKTU & TEMPAT', 'WHEN & WHERE'),

  location: get('Lihat Lokasi', 'See Location'),
  akadMuslim: get('Akad Nikah', 'Akad Nikah'),
  akadGeneral: get('Pemberkatan', 'Holy Matrimony'),
  reception: get('Ngunduh Mantu', 'Reception'),
  calendar: get('Ingatkan Acara', 'Add to Calendar'),
};