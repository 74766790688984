import React from 'react';
import { bool, func } from 'prop-types';
import loadable from '@loadable/component';

import {
  Box,
  Button,
  Center,
  Modal,
  ModalContent,
  ModalBody,
  ModalOverlay,
  ModalCloseButton,
  Text,
  Image,
  Heading,
  AspectRatio,
} from '@chakra-ui/react';

const LoadableGallerry = loadable(() =>
  import(/* webpackChunkName: "invitato-gallery-photos" */ './Gallery'),
);

import ImgLogo from '@/assets/icons/logo-invert.png';

import { photos as photosCompassion } from './api/photos-compassion';
import { IMG_PHOTO_CONTENT } from '@/constants/assets';

import txt from './locales';
import useLang from '@/hooks/useLang';
import {
  BOY_NAME_SHORT,
  GIRL_NAME_SHORT,
  IS_BOY_FIRST,
  THE_BRIDE,
  YOUTUBE_PREWEDDING_EMBED,
} from '@/constants/index';
import { ENABLE_VIDEO_PREWEDDING } from '@/constants/feature-flags';

function PhotoContent({ isOpen, onClose }) {
  const lang = useLang();
  return (
    <Modal
      size="full"
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside
    "
    >
      <ModalOverlay />
      <ModalContent maxW="500px">
        {false && <ModalCloseButton />}
        <ModalBody width="100%" padding="0">
          <Box bgImage={IMG_PHOTO_CONTENT} height="450px" bgSize="cover" bgPosition="top center">
            <Center>
              <Heading
                marginTop="42px"
                textColor="secondaryColorText"
                fontWeight="normal"
                fontSize="xs"
                textAlign="center"
                letterSpacing="3px"
                marginBottom="8px"
                color="black"
              >
                THE WEDDING OF
              </Heading>
            </Center>
            <Center>
              <Box
                textAlign="center"
                textColor="secondaryColorText"
                textTransform="uppercase"
                letterSpacing="4px"
                color="black"
              >
                <Heading fontWeight="normal" fontSize="2xl">
                  {IS_BOY_FIRST ? BOY_NAME_SHORT : GIRL_NAME_SHORT} &
                </Heading>
                <Heading fontWeight="normal" fontSize="2xl" marginTop="-15px">
                  {IS_BOY_FIRST ? GIRL_NAME_SHORT : BOY_NAME_SHORT}
                </Heading>
              </Box>
            </Center>
          </Box>
          {/* Images Cover */}
          <Box
            padding="32px 32px 0 32px"
            paddingTop="32px"
            textAlign="center"
            color="mainColorText"
          >
            <Text fontSize="md">
            {`"The greatest degree of inner tranquility comes from the development of love and compassion. The more we care for the happiness of others, the greater is our own sense of well-being."`}
            <br />— Dalai Lama
            </Text>
            {ENABLE_VIDEO_PREWEDDING && (
              <Box marginTop="120px">
                <AspectRatio marginTop="-100px" maxW="560px" ratio={16 / 9} boxShadow="xl">
                  <iframe
                    title={`Prewedding of ${THE_BRIDE}`}
                    src={YOUTUBE_PREWEDDING_EMBED}
                    allowFullScreen
                    loading="lazy"
                    //style={{ borderRadius: '16px' }}
                  />
                </AspectRatio>
              </Box>
            )}
            {/* Yogyakarta Section */}
            <Box paddingTop="42px">
              {false && (
                <Text fontFamily="cursive" fontSize="3xl">
                  Compassion
                </Text>
              )}
              <Box>{isOpen && <LoadableGallerry data={photosCompassion} />}</Box>
            </Box>
          </Box>
          {false && (
            <Center>
              <Image src={ImgLogo} maxW="100px" marginTop="16px" />
            </Center>
          )}
          <Center padding="0 0 48px 0">
            <Button
              size="sm"
              fontWeight="light"
              colorScheme="blackAlpha"
              color="white"
              fontSize="md"
              // textTransform="uppercase"
              fontStyle="italic"
              marginTop="32px"
              onClick={() => onClose()}
            >
              {txt.backContent[lang]}
            </Button>
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

PhotoContent.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
};

export default PhotoContent;
